<template>
  <div class="d-flex flex-column">
    <div class="page-slideout">
      <div class="section section-separate">
        <div class="container">
          <form class="content register-content">
            <div class="nav-responsive">
              <ul class="nav nav-register fiveitems">
                <template v-for="(item, index) in itemsStep">
                  <li
                    :key="index"
                    :class="item.active ? 'active' : item.finish ? 'done' : ''"
                    @click="item.finish && changeDetailStep(item.step)"
                  >
                    <a href="#">
                      <span class="icons icon-checkmark"></span>
                      <span class="number">{{ item.step }}</span>
                      <span class="text">{{ item.name }}</span>
                    </a>
                  </li>
                </template>
              </ul>
            </div>
            <div class="contentregister register-content">
              <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
                <div class="form-box success">
                  <img
                    class="icon fadeIn"
                    src="https://s3.gifyu.com/images/check-circle4058c587ae6cdcce.gif"
                    alt
                  />
                  <h3 class="title-md mb-2">
                    ขอแสดงความยินดี
                    <br />ท่านลงทะเบียนเสร็จแล้ว
                  </h3>

                  <div class="buttons">
                    <button
                      id="hover_signin_btn"
                      type="button"
                      class="btn xs gl-bt w-100"
                      style="text-transform: capitalize !important"
                      @mousemove="colorHover()"
                      @click="gotosignin()"
                    >
                      <span>{{ $t("Navbar.signin") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="sidebar">
            <div class="inner d-flex">
              <a :href="pathrightphoto" class="img-exemple" data-fancybox>
                <img
                  :src="pathrightphoto"
                  style="
                    border-radius: 0.938rem !important;
                    object-fit: cover !important;
                  "
                  alt
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import StepPropertyDetail from "@/views/signup/AccountDetail/PropertyDetail.vue";
import StepOwnerDetail from "@/views/signup/AccountDetail/OwnerDetail.vue";
import StepManagerDetail from "@/views/signup/AccountDetail/ManagerDetail.vue";
import StepConfirmation from "@/views/signup/AccountDetail/Confirmation.vue";

export default {
  components: {
    StepPropertyDetail,
    StepOwnerDetail,
    StepManagerDetail,
    StepConfirmation
  },
  data: () => ({
    stepdetail: 5,
    itemsStep: [],
    propertyData: {},
    propertyID: "",
    keepUserID: "",
    pathrightphoto: `${process.env.VUE_APP_PIC_API}/src/aigent_signup_bg.jpg`,
    propertyID: "",
    userToken: ""
  }),
  created() {
    const self = this;
    // self.stepdetail = 1
    self.propertyID = self.$route.params.propertyId;
    self.userToken = self.$route.params.userToken;
    self.$store.state.detailstep = self.stepdetail;
    if (JSON?.parse(localStorage.getItem("Property"))?._id) {
      self.propertyData = JSON?.parse(localStorage.getItem("Property"));
      self.propertyID = JSON?.parse(localStorage.getItem("Property"))._id;
      self.keepUserID = localStorage.getItem("userID");
    }
    self.initStep();
    self.initPropertyData();
  },
  mounted() {
    EventBus.$on("changeDetailStep", this.changeDetailStep);
    EventBus.$on("refreshPropertyDetailData", this.initPropertyData);
    this.colorHover();
  },
  methods: {
    colorHover() {
      const button = document.getElementById("hover_signin_btn");
      // console.log('hover_signin_btn')
      button.addEventListener("mousemove", e => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    changeDetailStep(num) {
      const self = this;
      EventBus.$emit("loadingPage");
      self.stepdetail = num;
      self.$store.dispatch("changeDetailStep", { detailstep: self.stepdetail });
      self.initStep();
    },
    initStep() {
      const self = this;
      self.itemsStep = [];
      // console.log(self.propertyData)
      self.itemsStep = [
        {
          name: self.$t("SignupStep.stepdetail1"),
          active: (self.stepdetail == 1 && true) || false,
          finish: self?.propertyData?.property_detail?.success || false,
          linkItems: {
            link: `signup/propertydetail/${self.propertyID}`,
            name: "SignupPropertyDetailScreen",
            params: { propertyId: self.propertyID }
          },
          step: 1
        },
        {
          name: self.$t("SignupStep.stepdetail2"),
          active: (self.stepdetail == 2 && true) || false,
          finish: self?.propertyData?.owner_detail?.success || false,
          linkItems: {
            link: `signup/ownerdetail/${self.propertyID}`,
            name: "SignupOwnerDetailScreen",
            params: { propertyId: self.propertyID }
          },
          step: 2
        },
        {
          name: self.$t("SignupStep.stepdetail3"),
          active: (self.stepdetail == 3 && true) || false,
          finish: self?.propertyData?.manager_detail?.success || false,
          linkItems: {
            link: `signup/managerdetail/${self.propertyID}`,
            name: "SignupManagerDetailScreen",
            params: { propertyId: self.propertyID }
          },
          step: 3
        },
        {
          name: self.$t("SignupStep.stepdetail4"),
          active: (self.stepdetail == 4 && true) || false,
          finish: self.stepdetail == 5,
          linkItems: {
            link: `signup/confirmation/${self.propertyID}`,
            name: "SignupConfirmationScreen",
            params: { propertyId: self.propertyID }
          },
          step: 4
        }
      ];
    },
    async initPropertyData() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token
            }
          }
        );
        if (res.status == 200) {
          temp = res.data.result;
          self.propertyData = res.data.result[0];
          localStorage.setItem("Property", JSON.stringify(self.propertyData));
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("sendPropertyDetailData", temp);
      }
    },
    async getUserData() {
      const self = this;
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/user?_id=${self.keepUserID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token
            }
          }
        );
        if (res.status == 200) {
          // self.allproperty = res?.data?.result[0]?.property[0]?.properties?.length || 0
          // self.maxproperty = res?.data?.result[0]?.property[0]?.property_number || 0
          // self.stepitems = `${res?.data?.result[0]?.property[0]?.properties?.length}/${res?.data?.result[0]?.property[0]?.property_number}` || '1/3'
          // self.canAddNewProperty = res?.data?.result[0]?.property[0]?.properties?.length == res?.data?.result[0]?.property[0]?.property_number ? false : true
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    gotosignin() {
      const self = this;
      const item = { link: "signin", name: "SigninScreen" };
      self.$store.dispatch("clearStoreData");
      self.changeStep(item);
    },
    changeStep(linkItems) {
      EventBus.$emit("changePathname", linkItems);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn.btn-searchmap {
  background-color: var(--pink) !important;
  border-color: var(--pink) !important;
}

.btn.btn-searchmap:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn-cancel-searchmap:hover {
  background-color: #777 !important;
  border-color: #777 !important;
}

.btn-cancel-searchmap:hover span {
  color: #fff !important;
}

.v-application .success {
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>
